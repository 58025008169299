import { Link, Typography } from "@mui/material";
import React from "react";
import "./../styles/Footer.css";

const Footer = () => (
  <footer className="footer">
    <Typography variant="h6" paragraph={false}>
      © 2022 Sedimentary Geochemistry and Paleoenvironments Project
    </Typography>
    <Typography variant="h6" paragraph={false}>
      Driven and Developed by <Link underline="none" href="https://aionis.io" target="_blank" fontWeight="600">Aionis</Link>
    </Typography>
    <Typography variant="h6" paragraph={false}>
      All Rights Reserved.
    </Typography>
  </footer>
);
export default Footer;
