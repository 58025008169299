import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from 'lodash';
import "./../styles/SidePanel.css";

import { ReactComponent as ApiLogo } from './../images/api-logo.svg';
import { ReactComponent as Map } from './../images/globe.svg';
import { Download, MenuOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";

type Props = {
  query: any;
  download: () => void;
  downloading: boolean;
  downloadMap: () => void;
  mapDownloading: boolean;
  toggleDrawer: () => void;
};

const SidePanel = ({ query, download, downloading, downloadMap, mapDownloading, toggleDrawer }: Props) => {
  return (
    <div className="sidenav">
      <IconButton
        className="sidebar-button"
        onClick={toggleDrawer}
      >
        <MenuOutlined />
      </IconButton>
      <div className="sidenav-actions">
        <Tooltip title={query}>
          <IconButton
            className="sidebar-button api-button"
          >
            <CopyToClipboard
              text={query}
            >
              <ApiLogo />
            </CopyToClipboard>
          </IconButton>
        </Tooltip>

        <IconButton className="sidebar-button" onClick={download} disabled={downloading}>
          {downloading ? (
            <CircularProgress
              size={24}
            />
          ) : (
            <Download />
          )}
        </IconButton>
        <IconButton className="sidebar-button" onClick={downloadMap} disabled={mapDownloading}>
          {mapDownloading ? (
            <CircularProgress
              size={24}
            />
          ) : (
            <Map height={24} width={24} />
          )}
        </IconButton>
      </div>
    </div>
  );
}

export default SidePanel;
