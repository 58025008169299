import { Typography } from '@mui/material';
import React from 'react';
import Typewriter from "typewriter-effect";

type Props = {
    text: string,
    // containerClassName?: string,
    textClassName?: string,
}

const TypedText = ({ text, textClassName }: Props) => {
    const onTypingInit = (typewriter: any) => {
        typewriter
            .typeString(text)
            .start();
    }

    // const classes = useStyles();

    return (
        <Typography variant="h4" className={textClassName}>
            <Typewriter
                options={{
                    delay: 75
                }}
                onInit={onTypingInit}
            />
        </Typography>
    )
}

// const useStyles = makeStyles(() => ({
//     container: {
//         padding: "0px !important",
//         position: 'relative',
//     },
//     placeholder: {
//         // visibility: "collapse",
//         position: 'absolute',
//         top: 0,
//         left: 0
//     }
// }));

export default TypedText;