import React, { ReactChild, useLayoutEffect, useRef } from "react";
import { Theme, Tooltip } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { useWidthOverflow } from "src/common/hooks/useOverflow";

type Props = {
  children: ReactChild;
};

const TableCellText = ({
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [hasOverflow, checkOverflow] = useWidthOverflow(ref);

  useLayoutEffect(() => {
    checkOverflow();
  }, [children]);

  const classes = useStyles();
  return (
    <Tooltip title={hasOverflow ? children : ""} enterDelay={1000}>
      <div ref={ref} className={classes.cellContent}>
        <span>
          {children}
        </span>
      </div>
    </Tooltip>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  cellContent: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))

export default TableCellText;