import { Link } from "@mui/material";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";
import "./../styles/Menu.css";

type Props = {
  className: string;
  name: string;
  href: string;
  exact: boolean;
}

const Menu = ({ className, name, href, exact }: Props) => (
  <div className={className}>
    <Link
      component={RouterLink}
      to={href || "/"}
      exact={exact}
    >
      {name?.toUpperCase()}
    </Link>
  </div>

);

export default Menu;
