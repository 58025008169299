import { TextField, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

type Props = {
    page: number;
    onPageChange?: (page: number) => void;
    totalPages: number;
}

const PageSelector = ({
    page,
    onPageChange,
    totalPages
}: Props) => {
    const [value, setValue] = useState(page + 1);
    const [error, setError] = useState(false);

    const handleValueChange = (e: any) => {
        const value = parseInt(e.target.value);
        setValue(value);

        if (Number.isNaN(value)) {
            setError(true);
        }
        else if (value > totalPages) {
            setError(true);
        } else if (value <= 0) {
            setError(true);
        } else {
            setError(false);
        }
    }

    const handleSubmit = () => {
        setError(false);
        if (error) {
            setValue(page + 1);
            return;
        }
        const newPage = value - 1;
        if (newPage === page) {
            return;
        }
        onPageChange && onPageChange(newPage);
    }

    const handleKeyPress = (e: any) => {
        if (e.key !== 'Enter') {
            return;
        }

        handleSubmit();
    }

    useEffect(() => {
        setValue(page + 1);
    }, [page]);

    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Typography variant='body2'>
                Page
            </Typography>
            <TextField
                className={classes.input}
                type="number"
                size="small"
                variant="standard"
                value={value}
                onBlur={handleSubmit}
                onChange={handleValueChange}
                onKeyPress={handleKeyPress}
                error={error}
            />
            <Typography variant='body2'>
                of {totalPages}
            </Typography>
        </div>
    )
}

const useStyles = makeStyles<Theme>((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    input: {
        marginTop: `${theme.spacing(0.5)} !important`,
        width: 70
    }
}));

export default PageSelector;