import React, { Component } from "react";
import ReactDOMServer from 'react-dom/server';
import axios from "axios";
import Table from "./Table/Table";
import SideBar from "./FilterPanel/FilterPanel";
import SidePanel from "./SidePanel";
import "./../styles/Search.css";
import ReferencesCell from "./ReferencesCell";
import _, { difference, keys, intersection, map, flatMap, omit, isNumber } from 'lodash';
import { saveAs } from 'file-saver';
import Map from "./Map";
import world from '../assets/world.json';
import { iron, carbon, sulfur, nitrogen, metalIsotope, customElements, defaultShowReferences, defaultShowAnalysis } from './attributeShow';
import { Alert, Snackbar } from "@mui/material";
import NoRecords from "./NoRecords";

const DEFAULT_SORTED = [{ id: "sample identifier", desc: false }];

const HIDE_ALERT_TIMEOUT = 5000;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      query: {
        type: "samples",
        filters: {},
        show: [],
        page: 0,
        count: 25,
        sorted: DEFAULT_SORTED,
      },
      column: [
        {
          columns: []
        }
      ],
      attributes: [],
      getSubColumns: null,
      getSubItems: null,
      loading: false,
      totalPages: 1,
      totalCount: 0,
      downloading: false,
      mapDownloading: false,
      error: '',

      drawerOpen: false,
    };

    this.changeType = this.changeType.bind(this);
    this.changeShow = this.changeShow.bind(this);
    this.constructMulti = this.constructMulti.bind(this);
    this.constructRange = this.constructRange.bind(this);
    this.sortedTimeout = null;
    this.errorTimeout = null;
  }

  // componentDidMount() {
  //   this.postSearch();
  // }

  changeType(value) {
    let query = this.state.query;
    query.type = value;

    const sectionsToCheck = flatMap([
      iron, carbon, sulfur, nitrogen, metalIsotope, customElements
    ], x => keys(x));

    let show = query.show;
    if (value !== 'analyses') {
      show = difference(show, keys(defaultShowAnalysis));
    } else {
      show = difference(show, sectionsToCheck);
    }

    let filters = query.filters;
    if (value !== 'analyses') {
      const ignoreFilters = ['ana_code', 'run_by_last', 'provider_lab'];
      filters = omit(filters, ignoreFilters);
    } else {
      filters = omit(filters, sectionsToCheck);
    }
    this.setState({ query: { ...query, show, filters } });
  }

  changeShow(value) {
    this.setState(prev => {
      let show = prev.query.show;
      var showIndex = show.indexOf(value);

      if (showIndex !== -1) {
        show = _.reject(prev.query.show, x => x === value);
      } else {
        show = [...prev.query.show, value];
      }

      return { query: { ...prev.query, show } }
    });
  }

  //Multi Select construct array
  constructMulti(attribute, arr) {
    let query = this.state.query;
    if (arr.length) {
      query.filters[attribute] = arr.map(option => option.value);
    } else {
      delete query.filters[attribute];
    }

    this.setState({ query });
  }

  constructRange(min, max, attribute) {
    let query = this.state.query;

    let numberMin = min;
    if (!isNumber(numberMin)) {
      numberMin = null;
    }
    let numberMax = max;
    if (!isNumber(numberMax)) {
      numberMax = null;
    }

    if (numberMin === null && numberMax === null) {
      delete query.filters[attribute];
    } else {
      query.filters[attribute] = [numberMin, numberMax];
    }
    this.setState({ query });
  }

  onTablePageChange = (page) => {
    this.setState(prev => ({ query: { ...prev.query, page }, loading: true }), this.postSearch);
  }

  onTablePageSizeChange = (count) => {
    this.setState(prev => ({ query: { ...prev.query, count, page: 0 }, loading: !!prev.data.length }), () => {
      if (this.state.loading) {
        this.postSearch();
      }
    });
  }

  onSearchPress = () => {
    this.setState(prev => ({ query: { ...prev.query, sorted: DEFAULT_SORTED, page: 0 }, loading: true }), this.postSearch);
  }

  onSortedChange = (sorted, column, shiftKey) => {
    if (shiftKey) {
      this.setState(prev => ({ query: { ...prev.query, sorted } }), () => {
        clearTimeout(this.sortedTimeout);
        this.sortedTimeout = setTimeout(() => {
          this.setState({ loading: true }, this.postSearch);
        }, 1000);
      });
    } else {
      clearTimeout(this.sortedTimeout);
      this.setState(prev => ({ query: { ...prev.query, sorted }, loading: true }), this.postSearch);
    }
  }

  clearShow = (show, type) => {
    const defaultValues = keys(defaultShowReferences);
    if (intersection(show, defaultValues).length) {
      let removeReferences = true;

      if (type === 'analyses') {
        removeReferences = false;
      } else {
        const sectionsToCheck = [
          iron, carbon, sulfur, nitrogen, metalIsotope, customElements
        ];
        for (const section of sectionsToCheck) {
          if (intersection(show, keys(section)).length) {
            removeReferences = false;
          }
        }
      }

      if (removeReferences) {
        show = difference(show, defaultValues);
      }
    }
    return show;
  }

  postSearch = async () => {
    const queryType = this.state.query.type;
    let response;

    let show = this.state.query.show;
    // show = this.clearShow(show, queryType);    

    try {
      response = await axios
        .post("api/frontend/post-paged", { ...this.state.query, show, page: this.state.query.page + 1 });
    } catch (e) {
      let error = '';

      const responseData = _.get(e, 'response.data');
      if (responseData) {
        error = responseData;
      } else {
        error = 'An error ocurred during search request';
      }
      this.setState({
        loading: false,
        error,
      }, () => {
        clearTimeout(this.errorTimeout);
        this.errorTimeout = setTimeout(() => {
          this.setState({ error: '' });
        }, HIDE_ALERT_TIMEOUT)
      });
      return;
    }

    const analysisProps = [
      "analysis reference (long)",
      "analysis reference (short)",
      "analysis result identifier",
      "analyte abundance",
      "analyte code",
      "analytical method code",
      "analytical method translation",
      "batch identifier",
      "experimental method code",
      "experimental method translation",
      "lab batch identifier",
      "lab provider name",
      "lower detection limit",
      "prep method",
      "provided by first name",
      "provided by last name",
      "run by first name",
      "run by last name",
      "units used",
      "upper detection limit",
    ];
    const innerDataPropName = 'subRows';

    let dataToUse = response.data.rows;
    let getSubColumns = null;
    let getSubItems = null;

    const firstItem = dataToUse[0] || {};

    let columnKeys = Object.keys(firstItem);

    // if (queryType === 'analyses') {
    //   columnKeys = _.difference(columnKeys, analysisProps);

    //   const groups = _.groupBy(dataToUse, x => x['sample identifier']);
    //   const firstItems = _.map(groups, x => ({
    //     ..._.omit(x[0], analysisProps),
    //     [innerDataPropName]: _.map(x, y => _.pick(y, analysisProps))
    //   }));
    //   dataToUse = firstItems;



    //   const subKeys = _.intersection(_.keys(firstItem), analysisProps);
    //   const subColumns = _.map(subKeys, x => ({
    //     Header: x,
    //     accessor: x
    //   }));

    //   getSubColumns = () => [{
    //     columns: subColumns
    //   }];
    //   getSubItems = x => x[innerDataPropName];
    // }

    let columns = columnKeys.filter(x => x !== innerDataPropName).map(key => {
      return {
        Header: key,
        accessor: key
      }
    });


    const totalPages = Math.ceil(response.data.count / this.state.query.count);

    this.setState({
      data: dataToUse,
      column: [{
        columns
      }],
      getSubColumns,
      getSubItems,
      loading: false,
      totalPages,
      totalCount: response.data.count
    });
  }

  showSettings(event) {
    event.preventDefault();
  }

  clearAll = () => {
    this.setState(prevState => ({
      query: {
        ...prevState.query,
        type: prevState.query.type,
        filters: {},
        show: [],
        page: 0,
      },
      data: [],
      column: [
        {
          columns: []
        }
      ],
      totalPages: 1,
      totalCount: 0
    }));
  }

  download = () => {
    this.setState({ downloading: true }, async () => {
      try {
        const show = this.clearShow(this.state.query.show, this.state.query.type);

        const response = await axios
          .post("api/frontend/csv", { ...this.state.query, show, page: this.state.query.page + 1 }, { responseType: 'blob' });
        saveAs(response.data, 'SGP.csv');
        this.setState({
          downloading: false,
        });
      } catch (e) {
        let error = '';

        const responseData = _.get(e, 'response.data');
        if (responseData) {
          error = responseData;
        } else {
          error = 'An error ocurred during search request';
        }
        this.setState({
          downloading: false,
          error,
        }, () => {
          clearTimeout(this.errorTimeout);
          this.errorTimeout = setTimeout(() => {
            this.setState({ error: '' });
          }, HIDE_ALERT_TIMEOUT)
        });
        return;
      }
    });
  }

  downloadMap = () => {
    this.setState({ mapDownloading: true }, async () => {
      try {
        const multiplier = 4;
        const w = 1024 * multiplier;
        const h = 800 * multiplier;
        const scale = 163 * multiplier;
        const yMargin = 100 * multiplier;

        // const rectH = 200;
        const rectH = 220 * multiplier;
        const rectW = 150 * multiplier;
        const rectM = 20 * multiplier;
        const rectP = 10 * multiplier;

        const textH = 18 * multiplier;

        const response = await axios.post("api/frontend/map", { ...this.state.query });

        // const map = (<Map
        //   analyses={response.data} 
        //   world={world} 
        //   w={w} 
        //   h={h} 
        //   scale={scale}
        //   yMargin={yMargin}
        //   multiplier={multiplier}
        //   rectH={rectH}
        //   rectW={rectW}
        //   rectM={rectM}
        //   rectP={rectP}
        //   textH={textH}
        // />);

        // this.setState({ mapDownloading: false, map });

        const can = document.createElement('canvas'); // Not shown on page
        const ctx = can.getContext('2d');
        const loader = new Image;                        // Not shown on page

        loader.width = can.width = w;
        loader.height = can.height = h;
        loader.onload = () => {
          ctx.drawImage(loader, 0, 0, loader.width, loader.height);
          saveAs(can.toDataURL("image/jpeg"), 'map.jpeg');
          this.setState({ mapDownloading: false });
        };
        loader.onerror = () => {
          this.setState({ mapDownloading: false });
        }
        // Pass world map to have an ability to fetch in parallel with data
        var svgAsXML = ReactDOMServer.renderToString(<Map
          analyses={response.data}
          world={world}
          w={w}
          h={h}
          scale={scale}
          yMargin={yMargin}
          multiplier={multiplier}
          rectH={rectH}
          rectW={rectW}
          rectM={rectM}
          rectP={rectP}
          textH={textH}
        />);
        loader.src = 'data:image/svg+xml,' + encodeURIComponent(svgAsXML);

      } catch (e) {
        let error = '';

        const responseData = _.get(e, 'response.data');
        if (responseData) {
          error = responseData;
        } else {
          error = 'An error ocurred during search request';
        }
        this.setState({
          mapDownloading: false,
          error,
        }, () => {
          clearTimeout(this.errorTimeout);
          this.errorTimeout = setTimeout(() => {
            this.setState({ error: '' });
          }, HIDE_ALERT_TIMEOUT)
        });
        return;
      }
    });
  }

  toggleDrawer = () => {
    this.setState(prev => ({
      drawerOpen: !prev.drawerOpen
    }));
  }

  closeDrawer = () => {
    this.setState({
      drawerOpen: false
    })
  }

  componentDidMount() {
    this.setState({
      drawerOpen: true
    })
  }

  render() {
    const { data, query, column, getSubColumns, getSubItems, loading, totalPages, downloading, mapDownloading, drawerOpen, totalCount } = this.state;

    return (
      <>
        <div id="outer-container">
          <SidePanel
            data={data}
            // TODO: should this fields be presented on the public API?
            query={`${JSON.stringify(_.omit(query, ['page', 'count', 'sorted']))}`}
            download={this.download}
            downloading={downloading}
            downloadMap={this.downloadMap}
            mapDownloading={mapDownloading}
            toggleDrawer={this.toggleDrawer}
          />
          <SideBar
            query={query}
            changeType={this.changeType}
            constructMulti={this.constructMulti}
            constructRange={this.constructRange}
            changeShow={this.changeShow}
            data={data}
            postSearch={this.onSearchPress}
            loading={loading}
            clearAll={this.clearAll}
            open={drawerOpen}
            onClose={this.closeDrawer}
          />

          <div id="page-wrap">
            {data.length ? (
              <Table
                column={column}
                data={data}
                getSubColumns={getSubColumns}
                getSubItems={getSubItems}
                loading={loading}
                onPageChange={this.onTablePageChange}
                pages={totalPages}
                totalCount={totalCount}
                page={query.page}
                pageSize={query.count}
                onPageSizeChange={this.onTablePageSizeChange}
                sorted={query.sorted}
                onSortedChange={this.onSortedChange}
              />
            ) : (
              <NoRecords />
            )}
          </div>
        </div>
        <Snackbar
          open={!!this.state.error}
          message="Note archived"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Alert severity="error">
            {this.state.error}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default Search;
