import React, { Component } from "react";
import "../../styles/Documentation.css";
import Navigation from "./Navigation";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./Home";
import Citation from "./Citation";
import WebSite from "./WebSite";
import PublicAPIDescription from "./PublicAPIDescription";
import SearchAttributes from "./SearchAttributes";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Video from "./Video";
import ProxyPrimers from "./ProxyPrimers";

class Documentation extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { location } = this.props;
        return (
            <TransitionGroup className="outer-doc">
                <Navigation />
                <CSSTransition
                    key={location.key}
                    timeout={{ enter: 300, exit: 300 }}
                    classNames={'documentation-content'}
                >
                    <Switch>
                        <Route exact path="/documentation" component={Home} />
                        <Route exact path="/documentation/citation" component={Citation} />
                        <Route exact path="/documentation/web-site" component={WebSite} />
                        <Route exact path="/documentation/api-description" component={PublicAPIDescription} />
                        <Route exact path="/documentation/search-attributes" component={SearchAttributes} />
                        <Route exact path="/documentation/video" component={Video} />
                        <Route exact path="/documentation/proxy-primers" component={ProxyPrimers} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        );
    }
}
export default withRouter(Documentation);
