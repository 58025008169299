import { Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const Video = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">Video</Typography>
        <div className="videos-container">
            <div className="videos-container-wrapper">
                <iframe src="https://www.youtube.com/embed/YsCeMItfM38?t=6s&rel=0" />
            </div>            
        </div>
    </div>
);
export default Video;