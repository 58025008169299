import React from 'react';
import { Drawer, IconButton, Theme } from '@mui/material';
import "../../styles/Dropdown.css";
import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

type Props = {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode,
    header?: React.ReactNode,
    footer?: React.ReactNode,
}

const DrawerPanel = ({
    open,
    onClose,
    children,
    header,
    footer
}: Props) => {
    const classes = useStyles();

    return (
        <Drawer
            classes={{
                paper: classes.drawerRoot
            }}
            anchor="left"
            open={open}
            onClose={onClose}

        >
            <div className={classes.drawerHeader}>
                {header || (
                    <div />
                )}
                <IconButton
                    classes={{
                        root: classes.drawerCloseButton
                    }}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            </div>
            <div className={classes.drawerContent}>
                {children}
            </div>
            {footer}
        </Drawer>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    drawerRoot: {
        width: '100%',
        maxWidth: 550,
        display: "flex",
        flexDirection: "column"
    },
    drawerHeader: {
        display: "flex",
        justifyContent: 'space-between',
        '&::before': {
            content: '""'
        }

    },
    drawerCloseButton: {
        alignSelf: "flex-start"
    },
    drawerContent: {
        padding: `0px ${theme.spacing(0.5)}px`,
        flex: 1,

        display: "flex",
        flexDirection: "column",
        gap: 8,
        overflowY: "auto",
    }
}))

export default DrawerPanel;