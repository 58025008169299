import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import Menu from "./Menu";
import "./../styles/NavBar.css";
import SGPLogo from './../images/sgp_logo.png';
import { AppBar } from "@mui/material";
import { mergeClassNames } from "src/common/funcs/mergeClassNames";

const staticPositionPaths = ['/search'];

class CustomNavBar extends Component {
  render() {
    const { history, className } = this.props;
    const pathname = _.get(history, 'location.pathname', '');
    const v2 = pathname.startsWith('/documentation');

    let position = "absolute"
    if (staticPositionPaths.some(x => pathname === x)) {
      position = "static";
    }

    let stickyNavClass = mergeClassNames(className, 'sticky-nav');
    if (v2) {
      stickyNavClass = mergeClassNames(stickyNavClass, 'sticky-nav-v2');
    }

    let itemClass = 'nav-bar-item';
    if (v2) {
      itemClass = mergeClassNames(itemClass, 'nav-bar-item-v2');
    }

    if (position === 'static') {
      stickyNavClass = mergeClassNames(stickyNavClass, 'nav-bar-static');
    }

    return (
      <AppBar className={stickyNavClass} position={position}>
        <img
          src={SGPLogo}
          width="150"
          height="75"
          className="navbar-icon"
        />
        <div className="nav-items">
          <Menu name="home" exact={true} className={itemClass} href="/" />
          <Menu name="documentation" className={itemClass} href="/documentation" />
          <Menu name="about" className={itemClass} href="/about" />
        </div>
      </AppBar>
    );
  }
}

export default withRouter(CustomNavBar);