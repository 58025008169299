import React, { Component } from 'react';
import * as d3 from 'd3';

class Map extends Component {
    constructor(props) {
        super(props);

        this.projection = d3.geoMercator()
            .scale(props.scale)
            .translate([props.w / 2, props.h / 2 + props.yMargin]);
        this.path = d3.geoPath()
            .projection(this.projection);

        this.gradientRange = d3.scaleLinear()
            .range(["#A1EB9B", "#FFAF45", "#78090C"]);
    }
    getColor = ds => {
        let color = '#333333';
        if (ds === 'SGP') {
            color = 'rgb(220, 26, 30)';
        } else if (ds == 'USGS-CMIBS') {
            color = 'rgb(254, 180, 80)';
        } else if (ds == 'USGS-NGDB') {
            color = 'rgb(113, 188, 142)';
        }
        return color;
    }

    getRadius = l => {
        const { multiplier } = this.props;
        let radius = 4 * multiplier;
        if (l >= 4000) {
            radius = 25 * multiplier;
        } else if (l >= 3000) {
            radius = 20 * multiplier;
        } else if (l >= 2000) {
            radius = 15 * multiplier;
        } else if (l >= 1000) {
            radius = 10 * multiplier;
        }
        return radius;
    }

    mapData = (analyzesData) => {
        const min = d3.min(analyzesData, x => +x['interpreted age']);
        const max = d3.max(analyzesData, x => +x['interpreted age']);
        const gradient = this.gradientRange
            .domain([min, min + max / 2, max]);

        const points = _.map(analyzesData, x => {
            const ds = x['data source'];
            const color = gradient(+x['interpreted age']);

            // const l = x.count;
            const l = 1;
            const radius = this.getRadius(l);

            const projection = this.projection([+x['site longitude'], +x['site latitude']]);
            const cx = projection[0];
            const cy = projection[1];
            return {
                ...x,
                color,
                radius,
                cx,
                cy,
                'interpreted age': +x['interpreted age']
            }
        });

        const orderedPoints = _.orderBy(points, x => x.radius, 'desc');
        return orderedPoints;
    }

    render() {
        const { analyses, w, h, world, multiplier } = this.props;
        const { rectH, rectW, rectM, rectP, textH } = this.props;
        //Width and height

        const circlesCenterX = rectP + this.getRadius(4000);
        const circlesBottomY = rectH - rectP - 80;

        const min = d3.min(analyses, x => +x['interpreted age']);
        const max = d3.max(analyses, x => +x['interpreted age']);

        const scale = d3.scaleLinear()
            .range([min, max])
            .domain([0, 3]);

        return (
            <svg width={w} height={h} xmlns="http://www.w3.org/2000/svg">
                <defs>
                <linearGradient id="Gradient" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="0%" stop-color="#78090C"/>
                    <stop offset="50%" stop-color="#FFAF45"/>
                    <stop offset="100%" stop-color="#A1EB9B"/>
                </linearGradient>
                </defs>
                <g>
                    <rect height="100%" width="100%" fill="#AAAAAA"></rect>
                </g>
                <g>
                  {
                    world.features.map((d, i) => (
                      <path
                          d={this.path(d)}
                          fill="#FFFFFF"
                          stroke="#AAAAAA"
                          strokeWidth={0.5}
                      />
                    ))
                  }
                </g>
                <g>
                    {
                        this.mapData(analyses).map((d, i) => (
                            <circle
                                cx={d.cx}
                                cy={d.cy}
                                r={d.radius}
                                fill={d.color}
                                stroke="#000000"
                                strokeWidth={0.5}
                            />
                        ))
                    }
                </g>
                <g>
                    <svg x={rectM} y={h - rectH - rectM} width={rectW} height={rectH}>
                        <rect height="100%" width="100%" fill="#FFFFFF" />

                        {/* <circle
                            cx={rectP + this.getRadius(1)}
                            cy={rectH - rectP - this.getRadius(1) - textH * 2}
                            r={this.getRadius(1)}
                            fill={this.getColor("SGP")}
                            strokeWidth={0}
                        />
                        <text font-size={textH} x={rectP + this.getRadius(1) + textH * 2} y={rectH - rectP - this.getRadius(1) + textH / 4 - textH * 2}>SGP</text>

                        <circle
                            cx={rectP + this.getRadius(1)}
                            cy={rectH - rectP - this.getRadius(1) - textH}
                            r={this.getRadius(1)}
                            fill={this.getColor("USGS-CMIBS")}
                            strokeWidth={0}
                        />
                        <text font-size={textH} x={rectP + this.getRadius(1) + textH * 2} y={rectH - rectP - this.getRadius(1) + textH / 4 - textH}>USGS-CMIBS</text>

                        <circle
                            cx={rectP + this.getRadius(1)}
                            cy={rectH - rectP - this.getRadius(1)}
                            r={this.getRadius(1)}
                            fill={this.getColor("USGS-NGDB")}
                            strokeWidth={0}
                        />
                        <text font-size={textH} x={rectP + this.getRadius(1) + textH * 2} y={rectH - rectP - this.getRadius(1) + textH / 4}>USGS-NGDB</text> */}

                        <text font-size={textH} x={rectP} y={rectP + textH}>Interpreted age</text>
                        <rect x={rectW - rectP - 20 * multiplier} y={rectP + rectH * 0.25 - textH / 2} width={20 * multiplier} height={rectH * 0.75 - rectP * 2 - textH / 2} fill="url(#Gradient)" />
                        {[0, 1, 2, 3].map(x => (
                            <text font-size={textH} x={rectW - rectP - 90 * multiplier} y={(rectP + rectH * 0.25 - textH / 2) + (rectH * 0.75 - rectP * 2) / 3 * (3 - x)}>{Math.round(scale(x))}</text>
                        ))}
                    </svg>
                </g>
            </svg>
        )
    }
}
export default Map;