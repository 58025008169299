import React, { useMemo } from "react";
import { Dictionary } from 'lodash';
import Dropdown from "../common/components/Dropdown";
import { Checkbox, CheckboxProps, FormControlLabel, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles';

type Props = {
  title: string;
  data: Dictionary<string>;
  isChecked: (value: string) => boolean;
  onChange: (value: string) => void;
  colWidth?: number;
}

const CheckboxGroup = ({ title, data, isChecked, onChange, colWidth }: Props) => {
  const entries = useMemo(() => Object.entries(data), [data]);

  const handleChange: CheckboxProps["onChange"] = (e) => {
    onChange(e.target.value);
  }

  const classes = useStyles({ colWidth });

  return (
    <Dropdown className={classes.dropdown} title={title}>
      {entries.map(([key, label]) => (
        <FormControlLabel
          key={key}
          label={label}
          classes={{
            label: classes.label
          }}
          control={(
            <Checkbox
              classes={{
                root: classes.checkbox
              }}
              size="small"
              value={key}
              checked={isChecked(key)}
              onChange={handleChange}
            />
          )}
        />
      ))}
    </Dropdown>
  )
}

type StyleProps = {
  colWidth?: number;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  dropdown: ({ colWidth }) => ({
    display: "grid",
    gridTemplateColumns: colWidth 
      ? `repeat(auto-fill, minmax(${colWidth}px, 1fr))` 
      : undefined,
    // gap: theme.spacing(0.5)
  }),
  checkbox: {
    padding: '0px 9px'
  },
  label: {
    wordBreak: "break-word",
  }
}))

export default CheckboxGroup;