import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
    title: string;
    children: React.ReactNode;
}

const FilterSection = ({ title, children }: Props) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography
                className={classes.title}
                variant="h6"
            >
                {title}
            </Typography>
            <div className={classes.content}>
                {children}
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        flexDirection: "column",
        gap: "inherit"
    },
    title: {
        alignSelf: 'center'
    },
    content: {
        padding: `0px ${theme.spacing(0.5)}`,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(0.25)
    }
}))

export default FilterSection;