import React from "react";
import Search from "./Search";
import About from "./About/About";
import Documentation from "./Documentation";

import { Redirect, Route, Switch } from "react-router-dom";
import "./../styles/App.css";
import Home from "./Home";

export const ROUTES = {
  home: '/',
  search: '/search',
  documentation: '/documentation',
  about: '/about',

}

const Router = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.home} component={Home} />
      <Route path={ROUTES.search} component={Search} />
      <Route path={ROUTES.documentation} component={Documentation} />
      <Route path={ROUTES.about} component={About} />
      <Redirect to={ROUTES.home} />
    </Switch>
  );
}

export default Router;
