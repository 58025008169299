import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import "./../styles/JumbotronUI.css";

import Video from './../images/video.mp4';
import { Button } from "@mui/material";
import Counters from "./Counters";
import TypedText from "../common/components/TypedText";

class JumbotronUI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: null,
      samples: null,
      countries: null
    };

    this.typingText = "Sedimentary Geochemistry and Paleoenvironments Project";
  }

  async componentDidMount() {
    await Promise.all([
      this.getInfoSamples(),
      this.getInfoResults(),
      this.getInfoCountries(),
    ]);
  }

  async getInfoSamples() {
    const that = this;

    return await axios
      .get("/api/v1/get/info/samples")
      .then(function (response) {
        that.setState({
          samples: Number(response.data[0].count)
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getInfoResults() {
    const that = this;

    return await axios
      .get("/api/v1/get/info/results")
      .then(function (response) {
        that.setState({
          results: Number(response.data[0].count)
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getInfoCountries() {
    const that = this;

    return await axios
      .get("/api/v1/get/info/countries")
      .then(function (response) {
        that.setState({
          countries: response.data[0].count
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  onSearchClick = () => {
    const { history } = this.props;
    history.push("/search");
  }

  // This is just personal preference.
  // I prefer to not show the the whole text area selected.

  render() {
    const { history } = this.props;

    const pathname = _.get(history, 'location.pathname');
    const routesToShow = [
      '/',
      '/about',
      '/about/',
    ];

    const visible = _.includes(routesToShow, pathname);

    const routesToShowSearch = [
      '/',
    ];

    const searchVisible = _.includes(routesToShowSearch, pathname);

    return (
      <div hidden={!visible}>
        <div className="jumbotron">
          <video className="video-background" autoPlay loop muted>
            <source src={Video} type="video/mp4" />
          </video>

          <div className="jumbotron-content">
            <TypedText textClassName="typing" text={this.typingText} />
            {searchVisible && (
              <Button
                className="search-button"
                variant="contained"
                size="large"
                onClick={this.onSearchClick}
              >
                Search
              </Button>
            )}
          </div>
        </div>
        <Counters
          countries={this.state.countries}
          samples={this.state.samples}
          results={this.state.results}
        />
      </div>
    );
  }
}

export default withRouter(JumbotronUI);
