import { Link } from "@mui/material";
import React, { Component } from "react";
import { NavLink as RouterLink } from 'react-router-dom';
import "../../styles/Documentation.css";

class Navigation extends Component {
  render() {
    return (
        <div default={false} className="documentation-nav-bar">
            <span className="documentation-nav-bar-header">Documentation</span>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation" exact={true}>
                Home Page
            </Link>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/citation" exact={true}>
                Citation
            </Link>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/web-site" exact={true}>
                Web Site
            </Link>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/api-description" exact={true}>
                Public API Description
            </Link>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/search-attributes" exact={true}>
                Search Attributes Dictionary
            </Link>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/video" exact={true}>
                Video Tutorial
            </Link>
            <Link component={RouterLink} className="documentation-nav-bar-item" to="/documentation/proxy-primers" exact={true}>
                Proxy Primers
            </Link>
        </div>
    );
  }
}

export default Navigation;