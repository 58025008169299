export const defaultShow = {
  alternate_name: "alternate name",
  height_meters: "height/depth",
  section_name: "section name",
  site_type: "site type",
  country: "country",
  state_province: "state/province",
  site_desc: "site description",
  coord_lat: "site latitude",
  coord_long: "site longitude",
  craton_terrane: "craton/terrane",
  basin_name: "basin name",
  basin_type: "basin type",
  meta_bin: "metamorphic bin",
  earliest_date: "earliest collection time",
  latest_date: "latest collection time",
  collector_first: "collector first name",
  collector_last: "collector last name",
  // geol_ctxt_notes: "geological context notes",
  // lithostrat_desc: "lithostratigraphic description",
  lithostrat_verb: "verbatim lithostratigraphy",
  strat_name: "stratigraphy name",
  strat_name_long: "long stratigraphy name",
  environment_bin: "environmental bin",
  environment_notes: "environment notes",
  environment_verb: "verbatim environment",
  is_turbiditic: "turbiditic (t/f)",
  biostrat_verb: "verbatim biostratigraphy",
  biozone_name: "biozone name",
  age_verb: "verbatim geological age",
  age_ics_name: "ics age",
  lithology_name: "lithology name",
  lithology_type: "lithology type",
  lithology_class: "lithology class",
  lithology_text: "lithology texture",
  // lithology_text_desc: "lithology texture description",
  lithology_comp: "lithology composition",
  // lithology_comp_desc: "lithology composition description",
  lithology_notes: "lithology notes",
  color_name: "color name",
  // color_qualifier: "color qualifier",
  // color_shade: "color shade",
  munsell_code: "munsell code",
  fossil_verb: "verbatim fossil",
  fossil_name: "fossil name",
  sedstruct_name: "sedimentary structure name",
  // sedstruct_desc: "sedimentary structure description",
  interpreted_age: "interpreted age",
  interpreted_age_notes: "interpreted age justification",
  max_age: "max age",
  min_age: "min age",
  sample_notes: "sample notes",
  project_name: "project name",
  project_type: "project type",
  data_source: "data source",
  // data_source_desc: "data source description",
};

export const defaultShowAnalysis = {
  run_by_first: "run by first name",
  run_by_last: "run by last name",
  provider_first: "provided by first name",
  provider_last: "provided by last name",
  provider_lab: "lab provider name",
  batch_id: "batch identifier",
  batch_lab_id: "lab batch identifier",
  geochem_method: "prep method",
  experiment_method_code: "experimental method code",
  experiment_method: "experimental method translation",
  analytical_method_code: "analytical method code",
  analytical_method: "analytical method translation",
  upper_detection: "upper detection limit",
  lower_detection: "lower detection limit",
};

export const defaultShowReferences = {
  analysis_ref_long: "analysis reference (long)",
  analysis_ref_short: "analysis reference (short)"
};

export const analytesShow = {
  alternate_name: "alternate name",
  height_meters: "height/depth",
  section_name: "section name",
  site_type: "site type",
  country: "country",
  state_province: "state/province",
  site_desc: "site description",
  coord_lat: "site latitude",
  coord_long: "site longitude",
  craton_terrane: "craton/terrane",
  basin_name: "basin name",
  basin_type: "basin type",
  meta_bin: "metamorphic bin",
  earliest_date: "earliest collection time",
  latest_date: "latest collection time",
  collector_first: "collector first name",
  collector_last: "collector last name",
  geol_ctxt_notes: "geological context notes",
  lithostrat_desc: "lithostratigraphic description",
  lithostrat_verb: "verbatim lithostratigraphy",
  strat_name: "stratigraphy name",
  strat_name_long: "long stratigraphy name",
  environment_bin: "environmental bin",
  environment_notes: "environment notes",
  environment_verb: "verbatim environment",
  is_turbiditic: "turbiditic (t/f)",
  biostrat_verb: "verbatim biostratigraphy",
  biozone_name: "biozone name",
  age_verb: "verbatim geological age",
  age_ics_name: "ics age",
  lithology_name: "lithology name",
  lithology_type: "lithology type",
  lithology_class: "lithology class",
  lithology_text: "lithology texture",
  lithology_text_desc: "lithology texture description",
  lithology_comp: "lithology composition",
  lithology_comp_desc: "lithology composition description",
  lithology_notes: "lithology notes",
  color_name: "color name",
  color_qualifier: "color qualifier",
  color_shade: "color shade",
  munsell_code: "munsell code",
  fossil_verb: "verbatim fossil",
  fossil_name: "fossil name",
  sedstruct_name: "sedimentary structure name",
  sedstruct_desc: "sedimentary structure description",
  interpreted_age: "interpreted age",
  interpreted_age_notes: "interpreted age justification",
  max_age: "max age",
  min_age: "min age",
  sample_notes: "sample notes",
  project_name: "project name",
  project_type: "project type",
  data_source: "data source",
  data_source_desc: "data source description",
  run_by_first: "run by first name",
  run_by_last: "run by last name",
  provider_first: "provided by first name",
  provider_last: "provided by last name",
  provider_lab: "lab provider name",
  batch_id: "batch identifier",
  batch_lab_id: "lab batch identifier",
  geochem_method: "prep method",
  experiment_method_code: "experimental method code",
  experiment_method: "experimental method translation",
  analytical_method_code: "analytical method code",
  analytical_method: "analytical method translation",
  upper_detection: "upper detection limit",
  lower_detection: "lower detection limit",
  analysis_ref_long: "analysis reference (long)",
  analysis_ref_short: "analysis reference (short)"
};
// //iron

export const iron = {
  fe: "Fe (wt%)",
  fe_carb: "Fe-carb (wt%)",
  fe_ox: "Fe-ox (wt%)",
  fe_mag: "Fe-mag (wt%)",
  fe_py: "Fe-py (wt%)",
  fe_hr: "FeHR",
  fe_hr_fe_t: "FeHR/FeT",
  fe_py_fe_hr: "Fe-py/FeHR",
  fe_t_al: "FeT/Al"
};

//Carbon
export const carbon = {
  tic: "TIC (wt%)",
  toc: "TOC (wt%)",
  tot_c: "Total Carbon (wt%)",
  del_13c_carb: "Delta13C-carb (permil)",
  del_13c_org: "Delta13C-org (permil)",
  tmax: "Tmax (degC)",
  s2: "S2 (mgHC/g)",
  s1: "S1 (mgHC/g)",
  s3: "S3 (mgCO2/g)"
};

// Sulfur
export const sulfur = {
  su: "S (wt%)",
  del_34s_py: "Delta34S-py (permil)",
  del_34s_cas: "Delta34S-cas (permil)",
  del_34s_gyp: "Delta34S-gyp (permil)",
  del_34s_obs: "Delta34S-obs (permil)",
  del_34s_bulk: "Delta34S-bulk (permil)",
  s_py: "S-py (wt%)",
  s_org: "S-org (wt%)",
  s_so4: "S-SO4 (wt%)",
  cas: "CAS (ppm)"
};

export const nitrogen = {
  n: "N (wt%)",
  del_15n: "Delta15N (permil)",
  cn_ratio: "C:N (atomic)"
};
// Nitrogen

export const metalIsotope = {
  del_238u: "Delta238U (permil)",
  del_98mo: "Delta98Mo (permil)",
  nd143_nd144: "Nd143/Nd144",
  os187_os188i: "Os187/Os188(I)",
  se82_se78: "Se82/Se78",
  del_56fe_t: "Delta56Fe-T (permil)",
  del_56fe_py: "Delta56Fe-py (permil)"
};

export const customElements = {
  ag: "Ag (ppm)",
  alu: "Al (wt%)",
  ars: "As (ppm)",
  au: "Au (ppb)",
  ba: "Ba (ppm)",
  be: "Be (ppm)",
  bi: "Bi (ppm)",
  ca: "Ca (wt%)",
  cd: "Cd (ppm)",
  ce: "Ce (ppm)",
  co: "Co (ppm)",
  cr: "Cr (ppm)",
  cs: "Cs (ppm)",
  cu: "Cu (ppm)",
  dy: "Dy (ppm)",
  er: "Er (ppm)",
  eu: "Eu (ppm)",
  ga: "Ga (ppm)",
  ge: "Ge (ppm)",
  gd: "Gd (ppm)",
  hf: "Hf (ppm)",
  hg: "Hg (ppm)",
  ho: "Ho (ppm)",
  ind: "In (ppm)",
  k: "K (wt%)",
  loi: "LOI (wt%)",
  la: "La (ppm)",
  li: "Li (ppm)",
  lu: "Lu (ppm)",
  mg: "Mg (wt%)",
  mn: "Mn (ppm)",
  mo: "Mo (ppm)",
  na: "Na (wt%)",
  nb: "Nb (ppm)",
  nd: "Nd (ppm)",
  ni: "Ni (ppm)",
  p: "P (ppm)",
  pb: "Pb (ppm)",
  pr: "Pr (ppm)",
  rb: "Rb (ppm)",
  re: "Re (ppm)",
  sb: "Sb (ppm)",
  sc: "Sc (ppm)",
  se: "Se (ppm)",
  si: "Si (wt%)",
  sm: "Sm (ppm)",
  sn: "Sn (ppm)",
  sr: "Sr (ppm)",
  ta: "Ta (ppm)",
  tb: "Tb (ppm)",
  te: "Te (ppm)",
  th: "Th (ppm)",
  ti: "Ti (wt%)",
  tl: "Tl (ppm)",
  tm: "Tm (ppm)",
  u: "U (ppm)",
  v: "V (ppm)",
  w: "W (ppm)",
  y: "Y (ppm)",
  yb: "Yb (ppm)",
  zn: "Zn (ppm)",
  zr: "Zr (ppm)"
};
