import { Collapse, Theme, Typography } from "@mui/material";
import React, { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

type Props = {
  title: string,
  children: React.ReactNode,
  className: string
}

const Dropdown = ({ title, children, className }: Props) => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(prev => !prev);
  }

  const classes = useStyles();
  return (
    <>
      <div className={classes.dropdownHandle} onClick={toggle}>
        <Typography variant="body1">
          {title}
        </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse
        classes={{
          wrapper: classes.childrenWrapper,
          wrapperInner: className,
          hidden: classes.hidden
        }}
        in={open}
        mountOnEnter={true}
      >
        {children}
      </Collapse>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dropdownHandle: {
    cursor: "pointer",
    backgroundColor: "rgba(126,147,183,.57)",
    borderRadius: theme.spacing(0.5),
    padding: `0px ${theme.spacing(1)}`,
  
    display: "flex",
    gap: theme.spacing(1.5),
    justifyContent: "space-between",
    alignItems: "center",
  },
  childrenWrapper: {
    padding: `${theme.spacing(1)} 0px`,
  },
  hidden: {
    display: 'none'
  }
}));

export default Dropdown;