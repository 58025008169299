import React from "react";
import "../../styles/About.css";

import Erik from '../../images/erik_head_shot.jpg';
import Farrell from '../../images/farrell.jpg';
import Davej from '../../images/davej.jpg';
import Pplanavsky from "../../images/planavsky.jpg";
import Kim_lau from "../../images/kim_lau.jpg";
import Samawi from "../../images/samawi.jpg";
import Sufian from "../../images/sufian.png";
import Aionis from "../../images/aionis.jpg";
import { Link, Typography } from "@mui/material";
import PhotoThumbnail from "../../common/components/PhotoThumbnail";

const About = () => {
  return (
    <div className="about">
      <Typography variant="h4" color="primary" className="text-header">
        Team
      </Typography>
      <Typography variant="body2" paragraph={true} className="text-content">
        The SGP started as a collaborative project between researchers at
        Stanford University, Trinity College Dublin, Yale University, and
        Harvard University, but now includes many members from diverse
        institutions and countries.
      </Typography>

      <Typography variant="h4" color="primary" className="text-header">
        Geology Team
      </Typography>

      <div className="photos-grid">
        <PhotoThumbnail
          src={Erik}
          alt="242x150"
          name="Erik Sperling"
          education="Stanford University"
        />
        <PhotoThumbnail
          src={Farrell}
          alt="242x150"
          name="Una Farrell"
          education="Trinity College, Dublin"
        />
        <PhotoThumbnail
          src={Davej}
          alt="242x150"
          name="David Johnston"
          education="Harvard University"
        />
        <PhotoThumbnail
          src={Pplanavsky}
          alt="242x150"
          name="Noah Planavsky"
          education="Yale University"
        />
        <PhotoThumbnail
          src={Kim_lau}
          alt="242x150"
          name="Kim Lau"
          education="Penn State University"
        />
      </div>
      <Typography variant="h4" color="primary" className="text-header">
        Developers
      </Typography>
      <div className="photos-grid">
        <PhotoThumbnail
          src={Samawi}
          alt="242x150"
          name="Rifaat Samawi"
          education="Yale University"
          link="https://www.linkedin.com/in/rifaat-samawi"
        />
        <PhotoThumbnail
          src={Sufian}
          alt="242x150"
          name="Sufyan Lattouf"
          education="Al-Zaytoonah University"
          link="https://www.linkedin.com/in/sufyan-lattouf-46046098"
        />
        <PhotoThumbnail
          src={Aionis}
          alt="242x150"
          name="Aionis"
          education="Los Gatos, CA"
          link="https://aionis.io"
        />
      </div>
      <Typography variant="h4" color="primary" className="text-header">
        Collaborative Team
      </Typography>
      <Typography variant="body2" paragraph={true} className="text-content">
        The heart of SGP is the collaborative team, who help provide
        geological context data and geochemical data and participate in
        Working Groups addressing particular questions about Earth’s
        environmental evolution. A full list of collaborative team members
        can be found on the SGP website: <Link
          href="http://sgp.stanford.edu/who-we-are"
          target="_blank"
        >https://sgp.stanford.edu/who-we-are
        </Link>
      </Typography>
    </div>
  );
}

export default About;
