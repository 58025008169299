import { Link, Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const WebSite = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">The Website</Typography>
        <div>
            <Typography variant="body2" paragraph={true}>
                When on the search page, users will be met with a blank table and
                open sidebar containing interactive UI elements. The table is
                intended to preview resulting data based on provided search terms
                whilst the sidebar is the mechanism by which users select their
                search options. The search options are categorized into the
                following:
            </Typography>
            <Typography variant="body2" paragraph={true}>
                <ul>
                    <li>
                        Type: Only contains one search option which is selecting the
                        search type (more on that later)
                    </li>
                    <li>
                        Filters: Contains search options that allow the user to pursue
                        samples/analyses of interest
                    </li>
                    <li>
                        Show: Contains search options that determine what columns will
                        appear on the table and exported results
                    </li>
                </ul>
            </Typography>
            <Typography variant="body2" paragraph={true}>
                The type search option is important to consider when choosing how
                resulting geochemical data ought to be organized. The search type
                must be selected before anything else, as certain search options
                only exist in certain search types. The two main search types are
                ‘samples’ and ‘analyses’, with ‘No HHXRF’ simply being a ‘samples’
                search that excludes any HHXRF data. A ‘samples’ search will list an
                individual sample on each row, with analytes taking up the columns
                and containing averaged abundance levels. Meanwhile, if users are
                looking to delve deeper into the data and understand the analyses
                and procedures that were executed to obtain each sample’s
                geochemical data, then the ‘analyses’ search type is useful as it
                lists every analysis recorded in the database in a separate row.
                Once the user has selected a search type, then they can start to
                search through the data. The filters section is used to prune
                through the database. Within the sub-categories, different search
                options exist that either accept lists of strings or numerical
                ranges. Every search option represents one search attribute, which
                equates to one type of data that exists in the SGP database and also
                a column on the preview table. The user can enter their desired
                search parameters within the filters section and will immediately
                see the table fill up or change to reflect the new search. However,
                the user will notice that the columns of the search attributes they
                are interacting with do not appear as columns on the preview table.
                To force them to appear, the user must check the search attributes
                show checkbox which can be found in the show section of the sidebar.
                With these tools, users can effectively slice and dice the SGP
                database as they please. When users have configured the search
                options to their liking and have confirmed this fact through the
                preview table, they can export the table as a csv file (for JSON use
                the public API) by clicking the export button found when the side
                pane is collapsed. Furthermore, they can hover their cursor over the
                API symbol to view the API call that was used to generate the
                current results and double click it to automatically copy the API
                call to their clipboard. Looking at the API call used for a given
                search is not only useful for more technologically sophisticated
                users, but is a great way to cite SGP data in academic papers
                because anyone reading the API call can get a copy of the data
                through the website or using the public API. We hope you will find
                the website simple and easy to use, and we appreciate any feedback
                you can give us through <Link href="mailto:esper@stanford.edu">esper@stanford.edu</Link>.
            </Typography>
        </div>
    </div>
);
export default WebSite;