import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

import "./../styles/App.css";
import JumbotronUI from "./JumbotronUI";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import Router from "./Router";

const Main = () => {
  const classes = useStyles();

  return (
    <div className={classes.appContainer}>
      <NavBar className={classes.navBar} />
      <JumbotronUI />
        <Router />
      <Footer />
    </div>
  );
}

const useStyles = makeStyles<Theme>(() => ({
  appContainer: {
    height: "100%",

    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
}));

export default Main;
