import React from "react";
import "./../styles/ReferencesCell.css";
import { InfoOutlined } from "@mui/icons-material";
import SampleDetails from "./SampleDetails";

const ReferencesCell = ({ original }) => {
    return (
        <SampleDetails
            sampleId={original['sample identifier']}
        >
            <div className="reference-cell-container">
                <InfoOutlined fontSize="small" />
            </div>
        </SampleDetails>
    )
}

export default ReferencesCell;
