import { RefObject, useState } from "react";

export const useWidthOverflow = (ref: RefObject<HTMLElement>): [boolean, () => void, () => void] => {
    const [hasOverflow, setHasOverflow] = useState(false);

    const checkOverflow = () => {
        const component = ref.current;
        if (!component) {
            return;
        }
        const newHasOverflow = component.offsetWidth < component.scrollWidth;
        setHasOverflow(newHasOverflow);
    }

    const reset = () => setHasOverflow(false);

    return [hasOverflow, checkOverflow, reset];
}

export const useHeightOverflow = (ref: RefObject<HTMLElement>) => {
    const [hasOverflow, setHasOverflow] = useState(false);

    const checkOverflow = () => {
        const component = ref.current;
        if (!component) {
            return;
        }
        const newHasOverflow = component.offsetHeight < component.scrollHeight;
        setHasOverflow(newHasOverflow);
    }

    const reset = () => setHasOverflow(false);

    return [hasOverflow, checkOverflow, reset];
}