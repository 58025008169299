import React, { useLayoutEffect } from "react";

import { BrowserRouter } from "react-router-dom";
import "./../styles/App.css";
import TagManager from "react-gtm-module";
import { CONFIG_CONSTANTS } from "../constants";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Main from "./Main";

const useGTM = process.env.REACT_APP_USE_GTM?.toLocaleLowerCase() === 'true';

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover"
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        }
      },
      defaultProps: {
        arrow: true
      }
    }
  }
});

const App = () => {
  useLayoutEffect(() => {
    const gtmId = CONFIG_CONSTANTS.GOOGLE_GTAG_KEY;
    if (useGTM && gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
