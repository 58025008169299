import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import configAxios from './config/axios.config';
configAxios();

// Global styling
import './styles/main.css';

ReactDOM.render(<App />, document.getElementById('root'));
