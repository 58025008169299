import { Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const LINKS = [
    { title: "SGP Proxy Primer: Carbon isotopes", url: "https://www.youtube.com/embed/xXdYWXP6hxU" },
    { title: "SGP Proxy Primer: Sulfur isotopes", url: "https://youtube.com/embed/5nbf9JtEVlU"},
    { title: "SGP Proxy Primer: I/Ca redox proxy", url: "https://youtube.com/embed/wkHys_KG91A"},
    { title: "SGP Proxy Primer: Re-Os geochronology and Os isotopes (Dr. Alan Rooney)", url: "https://www.youtube.com/embed/pFRMgyohXbo" },
    { title: "SGP Proxy Primer: Nitrogen isotopes (Dr. Eva Stüeken)", url: "https://www.youtube.com/embed/2cNkWSPqMo4" },
]

const ProxyPrimers = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">Proxy Primers</Typography>
        <div>
            <Typography variant="body2" paragraph={true}>
                The SGP Proxy Primers aim to accessibly explain the basics of a geochemical proxy or method to a broad audience of geologists, geochemists, and paleontologists. The goal is to help our community learn the promise, methodology, and caveats of a given proxy. The live talks are recorded for our YouTube channel; SGP team members have an opportunity to informally ask questions of the expert in an unrecorded Q&A session following each presentation.
            </Typography>
            <div className="videos-container">
                {LINKS.map(link => (
                    <div>
                        <div className="video-title">{link.title}</div>
                        <div className="videos-container-wrapper">
                            <iframe src={link.url} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);
export default ProxyPrimers;