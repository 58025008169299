import { Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const Home = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">Home Page</Typography>
        <div>
            <Typography variant='body2' paragraph={true}>
                Welcome to the Sedimentary Geochemistry and Paleoenvironments search
                website. We here at the SGP project are committed to providing global
                access to geochemical sample data.
            </Typography>
            <Typography variant='body2' paragraph={true}>
                We have acquired sample data from
                volunteering labs, dived into past studies and revived old data
                sources to compile the SGP database and search service you see before
                you. The search service allows the public to query our database either
                through this website which is designed for casual users or our public
                API intended to give technologically inclined users the ability to
                perform any and all searches.
            </Typography>
            <Typography variant='body2' paragraph={true}>
                This page is serves as a guide and
                documentation on how to use our search services and what search
                attributes are available.
            </Typography>
        </div>
    </div>
);
export default Home;