import React, { useState } from "react";
import axios from 'axios';
import _ from 'lodash';
import "./../styles/SampleDetails.css";
import { Tooltip, Typography } from "@mui/material";

type Props = any;
type State = {
    sample: any,
    loading: boolean,
    loadingDots: number
}

const SampleDetails = ({ sampleId, children }: Props) => {
    const [state, setState] = useState<State>({
        sample: null,
        loading: true,
        loadingDots: 0
    });

    const afterShow = async () => {
        if (state.sample) {
            setState({
                loading: true,
                loadingDots: 0,
                sample: null
            });
        }

        const interval = setInterval(() => {
            setState(prevState => {
                let loadingDots = prevState.loadingDots + 1;
                if (loadingDots > 3) {
                    loadingDots = 0;
                }
                return { ...prevState, loadingDots };
            })
        }, 200);

        const query = {
            type: 'samples',
            filters: {
                sample_id: sampleId
            },
            show: [
                'alternate_name',
                'section_name',
                'height_meters',
                'site_type',
                'state_province',
                'country',
                'site_desc',
                'coord_lat',
                'coord_long',
                'sample_notes',

                'craton_terrane',
                'basin_name',
                'basin_type',
                'meta_bin',
                'environment_bin',
                'strat_name_long',
                'age_ics_name',
                'interpreted_age',
                'min_age',
                'max_age',
                'interpreted_age_notes',
                'color_shade',
                'color_name',
                'lithology_text',
                'lithology_comp',
                'lithology_name',
                'lithology_notes',
                'lithostrat_verb',

                'project_name',
                'collector_last',
                'geol_context_provided_by_first_name',
                'geol_context_provided_by_last_name',
                'interpreted_age_provided_by_first_name',
                'interpreted_age_provided_by_last_name',
            ]
        };

        try {
            const res = await axios
                .post("/api/v1/post", query);

            const sample = res.data[0];
            const providedByFullnames = res.data.map((x: any) => {
                if (!x.geol_context_provided_by_first_name || !x.geol_context_provided_by_last_name) {
                    return;
                }
                const fullname = `${x.geol_context_provided_by_first_name} ${x.geol_context_provided_by_last_name}`;
                return fullname;
            }).filter((x: any) => !!x);
            const uniqueProvidedByFullnames = _.uniq(providedByFullnames);
            sample.geol_context_provided_by = uniqueProvidedByFullnames.join(', ');

            const projectNames = res.data.map((x: any) => x['project name']);
            const uniqueProjectNames = _.uniq(projectNames);
            sample.project_names = uniqueProjectNames.join(', ');

            const interpretedAgeProviders = res.data.map((x: any) => {
                if (!x.interpreted_age_provided_by_first_name || !x.interpreted_age_provided_by_last_name) {
                    return;
                }
                const fullname = `${x.interpreted_age_provided_by_first_name} ${x.interpreted_age_provided_by_last_name}`;
                return fullname;
            }).filter((x: any) => !!x);
            const uniqueInterpretedAgeProviders = _.uniq(interpretedAgeProviders);
            sample.interpreted_age_provided_by = uniqueInterpretedAgeProviders.join(', ');

            setState({
                loading: false,
                loadingDots: 0,
                sample,
            });
        } finally {
            clearInterval(interval);
        }
    }


    const afterHide = () => {
        setState(prev => ({
            ...prev,
            loadingDots: 0
        }));
    }

    const { loading, sample, loadingDots } = state;
    const dotsString = '.'.repeat(loadingDots); 

    return (
        <Tooltip
            classes={{
                tooltip: "sample-details-tooltip",
            }}
            onOpen={afterShow}
            onClose={afterHide}
            enterDelay={300}
            leaveDelay={300}
            title={function() {
                if (loading) {
                    return `Loading${dotsString}`;
                }
                return (
                    <div className="sample-details-container">
                        <div className="sample-details-section">
                            <Typography variant="h5" paragraph={false} className="sample-details-section-header">Sample information</Typography>
                            <div className="sample-details-content">
                                <span>Sample name:</span>
                                <span>{sample['sample original name']}</span>

                                <span>Alternate name:</span>
                                <span>{sample['alternate name']}</span>

                                <span>Section name:</span>
                                <span>{sample['section name']}</span>

                                <span>Height/depth (m):</span>
                                <span>{sample['height/depth']}</span>

                                <span>State, Country:</span>
                                <span>{[sample['state/province'], sample['country']].filter(x => !!x).join(', ')}</span>

                                <span>Site description:</span>
                                <span>{sample['site description']}</span>

                                <span>Latitude:</span>
                                <span>{sample['site latitude']}</span>

                                <span>Latitude:</span>
                                <span>{sample['site longitude']}</span>

                                <span>Sample notes:</span>
                                <span>{sample['sample notes']}</span>
                            </div>
                        </div>
                        <div className="sample-details-section">
                            <Typography variant="h5" paragraph={false} className="sample-details-section-header">Geological context</Typography>
                            <div className="sample-details-content">
                                <span>Craton/terrane:</span>
                                <span>{sample['craton/terrane']}</span>

                                <span>Basin name:</span>
                                <span>{sample['basin name']}</span>

                                <span>Basin type:</span>
                                <span>{sample['basin type']}</span>

                                <span>Metamorphic bin:</span>
                                <span>{sample['metamorphic bin']}</span>

                                <span>Environmental bin:</span>
                                <span>{sample['environmental bin']}</span>

                                <span>Stratigraphy name:</span>
                                <span>{sample['long stratigraphy name']}</span>

                                <span>ICS age:</span>
                                <span>{sample['ics age']}</span>

                                <span>Interpreted age (Ma):</span>
                                <span>{sample['interpreted age']}</span>

                                <span>Minimum Age:</span>
                                <span>{sample['min age']}</span>

                                <span>Maximum Age:</span>
                                <span>{sample['max age']}</span>

                                <span>Interpreted age notes:</span>
                                <span>{sample['interpreted age justification']}</span>
                                <span>Lithology:</span>
                                <span>{[
                                    sample['color shade'],
                                    sample['color name'],
                                    sample['lithology texture'],
                                    sample['lithology composition'],
                                    sample['lithology name'],
                                ].filter(x => !!x).join(' ')}</span>

                                <span>Lithology notes:</span>
                                <span>{sample['lithology notes']}</span>
                                <span>Verbatim lithostratigraphy:</span>
                                <span>{sample['verbatim lithostratigraphy']}</span>
                            </div>
                        </div>
                        <div className="sample-details-section">
                            <Typography variant="h5" paragraph={false} className="sample-details-section-header">Collection information</Typography>
                            <div className="sample-details-content">
                                <span>Project name:</span>
                                <span>{sample.project_names}</span>

                                <span>Collected by last name:</span>
                                <span>{sample['collector last name']}</span>

                                <span>Geological context provided by:</span>
                                <span>{sample.geol_context_provided_by}</span>

                                <span>Interpreted age provided by:</span>
                                <span>{sample.interpreted_age_provided_by}</span>
                            </div>
                        </div>
                    </div>
                )
            }()}
        >
            {children}
        </Tooltip>
    );
}

export default SampleDetails;
