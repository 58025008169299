import React, { useEffect, useMemo, useState } from "react";
import { ClassNameMap, TableBody, TableCell, TableHead, TablePagination, TableRow, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { uniqueId } from "lodash";
import ReferencesCell from "../ReferencesCell";
import { Table as MuiTable } from '@mui/material';
import HeaderCell from "./HeaderCell";
import { SortOrder } from "src/common/types/sorting";
import PageSelector from "./PageSelector";
import TableCellText from "./TableCellText";
import TableLoader from "./TableLoader";
import { useVariable } from "src/common/hooks/useVariable";

type Props = any;
type Row = any;
type Column = any;

const rowsPerPageOptions = [5, 10, 20, 25, 50, 100];

const newColumnMap = (column: any, classes: ClassNameMap<string>) => {
  let resColumns = column[0].columns;
  resColumns = resColumns.map((x: Column) => ({
    ...x,
    // flex,
    filterable: false,
    hideable: false,
    sortable: true,
    maxWidth: 500
  }));
  if (resColumns.length) {
    resColumns = [
      {
        className: classes.infoColumn,
        filterable: false,
        hideable: false,
        renderCell: ({ row }: any) => (
          <ReferencesCell original={row} />
        )
      },
      ...resColumns
    ];
  }

  return resColumns.map((x: Column) => ({
    ...x,
    field: x.accessor,
    headerName: x.Header
  }));;
}

const Table = ({
  data,
  column,
  page,
  onPageChange,
  sorted,
  onSortedChange,
  pageSize,
  onPageSizeChange,
  loading,
  totalCount,
  pages
}: Props) => {
  const [firstHandled, setFirstHandled] = useVariable(false);
  const [rows, setRows] = useState([]);

  const onToggleOrder = (id: string, sort: SortOrder) => {
    onSortedChange([{ id, desc: sort === "desc" }]);
  }

  const handleChangePage = (_e: unknown, page: number) => {
    onPageChange(page);
  }

  const handlePageSizeChange = (e: any) => {
    onPageSizeChange(e.target.value);
  }

  let order: SortOrder | undefined = undefined;

  if (sorted[0]) {
    order = sorted[0].desc ? "desc" : "asc";
  }

  const refreshRows = () => {
    const mappedRows = data.map((x: Row) => ({
      ...x,
      id: uniqueId(),
    }));
    setRows(mappedRows);
  }

  const onLoaderClosing = () => {
    refreshRows();
  }

  useEffect(() => {
    if (firstHandled) {
      return;
    }

    setFirstHandled(true);
    refreshRows();
  })

  const classes = useStyles();
  const columns = useMemo(() => newColumnMap(column, classes), [column, classes]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableWrapper}>
        <MuiTable
          classes={{
            root: classes.table
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((x: Column) => (
                <HeaderCell
                  key={x.accessor}
                  className={x.className}
                  id={x.accessor}
                  sortable={x.sortable}
                  sortBy={sorted[0]?.id}
                  order={order}
                  onToggleOrder={onToggleOrder}
                >
                  {x.Header}
                </HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: Row) => (
              <TableRow key={row.id}>
                {columns.map((col: Column) => (
                  <TableCell
                    key={`${row.id}_${col.accessor}`}
                    className={col.className}
                  >
                    {col.renderCell
                      ? col.renderCell({ row })
                      : (
                        <TableCellText>
                          {row[col.accessor]}
                        </TableCellText>

                      )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </MuiTable>
      </div>
      <TablePagination
        labelDisplayedRows={() => (
          <PageSelector
            page={page}
            totalPages={pages}
            onPageChange={onPageChange}
          />
        )}
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        count={totalCount}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handlePageSizeChange}
      />
      <TableLoader
        loading={loading}
        onClosing={onLoaderClosing}
      />
    </div>
  );
}

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  tableWrapper: {
    flex: 1,
    backgroundColor: theme.palette.common.white,
    minHeight: 400,
    position: 'relative',
    overflow: 'auto'
  },
  table: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    '& .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.grey[300],
      boxShadow: "0 2px 15px 0 rgb(0 0 0 / 15%)"
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(1),

      overflow: 'hidden',
      maxWidth: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    "& .MuiTableBody-root > .MuiTableRow-root:nth-of-type(odd)": {
      backgroundColor: theme.palette.grey[50]
    },
    "& .MuiTableBody-root > .MuiTableRow-root:hover": {
      backgroundColor: theme.palette.action.hover
    }
  },
  infoColumn: {
    width: '0%',
    wordSpace: 'nowrap'
  }
}))

export default Table;