import React, { Component, useMemo } from "react";
import RangeSelect from "./RangeSelect";
import "../styles/Select.css"

const RangeList = ({ constructRange, attributes, query }) => {
  const entries = useMemo(() => Object.entries(attributes), [attributes]);

  return (
    <div className="range-select-root">
      {entries.map((item) => (
        <RangeSelect
          key={item[0]}
          constructRange={constructRange}
          attribute={item[0]}
          title={item[1]}
          query={query}
        />
      ))}
    </div>
  );
}


export default RangeList;