import React, { ReactNode, useEffect, useRef, useState } from "react";
import _ from 'lodash';
import { Autocomplete, AutocompleteInputChangeReason, InputAdornment, TextField } from "@mui/material";
import { CONFIG_CONSTANTS } from "src/constants";
import axios from "axios";
import { useUpdatedEffect } from "src/common/hooks/useUpdatedEffect";

import "../styles/Select.css"
import AsyncMultiInfoLabel from "./AsyncMultiInfoLabel";

type LoadingState = "pending" | "loading" | "loaded" | "failed";

type Props = {
  attribute: string,
  params: any,
  label?: ReactNode,
  query: any,
  tip?: string
  onChange: (attribute: string, value: any[]) => void,
}

type Option = {
  label: string;
  value: string;
};

const AsyncMulti = ({ attribute, params, label, tip, query, onChange }: Props) => {

  const [loadingState, setLoadingState] = useState<LoadingState>("pending");
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);

  const opened = useRef(false);

  const getOptions = async (inputValue: string): Promise<Option[]> => {
    let queryParams = { ...params };
    if (!inputValue && attribute == "ana_code") {
      queryParams = {
        ...queryParams,
        limit: CONFIG_CONSTANTS.DEFAULT_LIMIT,
        attribute,
      };
    } else if (!inputValue) {
      queryParams = {
        ...queryParams,
        limit: CONFIG_CONSTANTS.DEFAULT_LIMIT,
        attribute,
      };
    } else {
      queryParams = {
        ...queryParams,
        limit: CONFIG_CONSTANTS.DEFAULT_LIMIT,
        attribute,
        current_search: inputValue
      };
    }

    var answer = await axios.post("/api/v1/post/attr", queryParams);

    return await answer.data.map((option: any) => ({
      value: option[attribute],
      label: option[attribute]
    }));
  }

  const loadItems = async () => {
    if (loadingState === "loading") {
      return;
    }

    setLoadingState("loading");
    try {
      const newOptions: Option[] = await getOptions(inputValue);
      const mappedOptions = mapOptionsToSelected(newOptions);
      setOptions(mappedOptions);

      setLoadingState("loaded");
    } catch (e) {
      setLoadingState("failed")
    }
  }

  const mapOptionsToSelected = (options: Option[]) => {
    return options.map(x => {
      const selected = selectedOptions.find(y => x.value === y.value);
      if (selected) {
        return selected;
      }

      return x;
    })
  }

  const onInputChange = (
    _event: React.SyntheticEvent,
    value: string,
    _reason: AutocompleteInputChangeReason,
  ) => {
    setInputValue(value);
  };

  const onOpen = () => {
    opened.current = true;
    setOpen(true);
    loadItems();
  }

  const onClose = () => {
    setOpen(false);
  }

  const handleChange = (_event: any, newValue: Option[]) => {
    onChange(attribute, newValue);
  }

  useEffect(() => {
    if (!opened.current) {
      return
    }

    const timeout = setTimeout(() => {
      loadItems();
    }, 500);

    return () => clearTimeout(timeout);
  }, [inputValue]);

  useUpdatedEffect(([prevParams]) => {
    if (!prevParams) {
      return;
    }

    if (_.isEqual(params, prevParams)) {
      return;
    }

    setLoadingState("pending");
  }, [params]);

  useEffect(() => {
    setSelectedOptions(prev => {
      const newSelectedOptions = (query.filters[attribute] || []).map((x: string) => {
        const existingOption = options.find(y => x === y.value);
        if (existingOption) {
          return existingOption;
        }

        const existingSelectedOption = prev.find(y => x === y.value);
        if (existingSelectedOption) {
          return existingSelectedOption;
        }

        return {
          value: x,
          label: x
        };
      });

      return newSelectedOptions;
    });
  }, [query.filters[attribute]]);

  return (
    <Autocomplete
      className="select"
      classes={{
        inputRoot: "select-root"
      }}
      multiple
      options={options}
      getOptionLabel={(option: Option) => option.label}
      loading={loadingState === "loading"}
      limitTags={4}
      onOpen={onOpen}
      onClose={onClose}
      open={open}
      inputValue={inputValue}
      onInputChange={onInputChange}
      value={selectedOptions}
      onChange={handleChange}
      disableCloseOnSelect={true}
      filterSelectedOptions={true}
      ChipProps={{
        color: "primary"
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder="Select..."
          InputLabelProps={{
            unselectable: "off",
            "aria-disabled": "false"
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {!!tip && (
                  <InputAdornment position="end" className="select-tip" >
                    <AsyncMultiInfoLabel
                      description={tip}
                    />
                  </InputAdornment>
                )}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AsyncMulti;
