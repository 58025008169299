import { Link, Typography } from '@mui/material';
import React from 'react';
import "../../styles/Documentation.css";

const Citation = () => (
    <div className="documentation-page">
        <Typography variant='h4' className="documentation-contents-header">Citation</Typography>
        <Typography variant='body2'>
            The Phase 1 data product is openly available. Users downloading and re-using this data in publications
            should cite Farrell et al., 2021, Geobiology, as the source. This paper can be accessed&nbsp;
            <Link href="https://onlinelibrary.wiley.com/doi/full/10.1111/gbi.12462" target="_blank" title="The Sedimentary Geochemistry and Paleoenvironments Project">here</Link>.
        </Typography>
    </div>
);
export default Citation;