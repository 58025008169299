import { TableCell, TableSortLabel, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { SortOrder } from 'src/common/types/sorting';

type Props = {
    className?: string;
    sortable?: boolean;
    sortBy?: string;
    id: string;
    order?: SortOrder;
    onToggleOrder?: (id: Props['id'], order: SortOrder) => void;
    children: ReactNode

}

export const HeaderCell = ({
  className,
  sortable = false,
  sortBy,
  id,
  order,
  onToggleOrder,
  children
}: Props) => {
  const classes = useStyles();

  const handleToggleOrder = () => {
    if (onToggleOrder) {
      const newOrder = order === 'asc' ? 'desc' : 'asc';
      onToggleOrder(id, newOrder);
    }
  }

  if (sortable) {
    return (
      <TableCell
        className={clsx(classes.cell, className)}
        sortDirection={sortBy === id ? order : false}
      >
        <TableSortLabel
          active={sortBy === id}
          direction={sortBy === id ? order : "asc"}
          onClick={handleToggleOrder}
        >
          {children}
        </TableSortLabel>
      </TableCell>
    )
  }

  return (
    <TableCell className={clsx(classes.cell, className)}>
      {children}
    </TableCell>
  )
}

const useStyles = makeStyles<Theme>(theme => ({
  cell: {
    letterSpacing: "0.001em",
    color: "#141E30",
    fontWeight: 500,
    padding: `12px ${theme.spacing(2)}px`
  },
}))

export default HeaderCell;