import axios from 'axios';
import { CONFIG_CONSTANTS } from '../constants';

// TODO: for dev only
export default () => {
    // axios.defaults.baseURL = process.env.REACT_APP_DATA_API_URL;
    // axios.defaults.baseURL = 'http://sgp-search.io/';
    axios.defaults.baseURL = CONFIG_CONSTANTS.API_HOST_PATH;
    // axios.defaults.auth = {
    //     username: 'frontend',
    //     password: 'Ogilvie1'        
    // }
}
