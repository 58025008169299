import React, { Component } from "react";

import "./../../styles/FilterPanel.css";
import "./../../styles/Select.css"
import AsyncMulti from "../AsyncMulti";
import RangeSelect from "../RangeSelect";
import Dropdown from "../../common/components/Dropdown";
import RangeList from "../RangeList";
import {
  customElements,
  iron,
  carbon,
  sulfur,
  metalIsotope,
  nitrogen,
  defaultShow,
  defaultShowAnalysis,
  defaultShowReferences
} from "../attributeShow";
import toolTips from "../toolTips";
import CheckboxGroup from "../CheckboxGroup";
import TypeSelector from "./TypeSelector";
import { Button, CircularProgress, IconButton, StepIcon, Tooltip, Typography } from "@mui/material";
import AsyncMultiInfoLabel from "../AsyncMultiInfoLabel";
import DrawerPanel from "../../common/components/DrawerPanel";
import { MenuOutlined } from "@mui/icons-material";
import FilterActions from "./FilterActions";
import FilterSection from "./FilterSection";

class SideBar extends Component {
  constructor(props) {
    super(props);
  }

  isChecked = (key) => {
    return this.props.query.show.includes(key);
  }

  render() {
    const {
      query,
      changeType,
      constructMulti,
      constructRange,
      changeShow,
      postSearch,
      clearAll,
      loading,
      open,
      onClose
    } = this.props;

    return (
      <DrawerPanel
        open={open}
        onClose={onClose}
        footer={(
          <FilterActions
            loading={loading}
            onSearch={postSearch}
            onClear={clearAll}
          />
        )}
        header={(
          <TypeSelector
            value={query.type}
            onChange={changeType}
          />
        )}
      >
        <FilterSection title="Sample Filters">
          <AsyncMulti
            onChange={constructMulti}
            query={query}
            attribute="original_name"
            label="Original Name"
          />
          {query.type === "analyses" && (
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="ana_code"
              label="Analyte Code"
            />
          )}

          <Dropdown title="Collection Site">
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="country"
              label="Country"
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="state_province"
              label="State/Province"
              params={{ countries: query.filters.country }}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="site_type"
              label="Site Type"
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="section_name"
              label="Section Name"
              tip={toolTips.section_name}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="craton_terrane"
              label="Craton/Terrane"
              tip={toolTips.craton_terrane}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="basin_name"
              label="Basin Name"
              tip={toolTips.basin_name}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="basin_type"
              label="Basin Type"
              tip={toolTips.basin_type}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="meta_bin"
              label="Metamorphic Name"
              tip={toolTips.metamorphic_bin}
            />
          </Dropdown>
          <Dropdown title="People/Batches/Insights">
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="collector_first"
              label="Collector's First Name"
              tip={toolTips.collector}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="collector_last"
              label="Collector's Last Name"
              tip={toolTips.collector}
            />
            {query.type === "analyses" && (
              <>
                <AsyncMulti
                  onChange={constructMulti}
                  query={query}
                  attribute="run_by_last"
                  label="Run By Last Name"
                />
                <AsyncMulti
                  onChange={constructMulti}
                  query={query}
                  attribute="provider_lab"
                  label="Lab Provider"
                />
              </>
            )}
          </Dropdown>
          <Dropdown title="Geological Environmental Context">
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="strat_name_long"
              label="Long Stratigraphy Name"
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="environment_bin"
              label="Environmental Bin"
              tip={toolTips.environment_bin}
            />
            <div className="range-select-root">
              <RangeSelect
                constructRange={constructRange}
                attribute="interpreted_age"
                title="Interpreted Age in Ma (Younger-Older):  "
                query={query}
              />
            </div>
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="age_ics_name"
              label="ICS Age"
              tip={toolTips.age_ics_name}
            />
          </Dropdown>
          <Dropdown title="Project/Data Source">
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="project_name"
              label="Project Name"
              tip={toolTips.collector}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="data_source"
              label="Data Source"
              tip={toolTips.data_source}
            />
          </Dropdown>

          <Dropdown title="Lithology">
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="lithology_name"
              label="Lithology Name"
              tip={toolTips.lithology_name}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="lithology_type"
              label="Lithology Type"
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="lithology_class"
              label="Lithology Class"
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="lithology_text"
              label="Lithology Texture"
              tip={toolTips.lithology_text}
            />
            <AsyncMulti
              onChange={constructMulti}
              query={query}
              attribute="lithology_comp"
              label="Lithology Composition"
              tip={toolTips.lithology_comp}
            />
          </Dropdown>
        </FilterSection>
        {(query.type === "samples" || query.type === "nhhxrf") && (
          <FilterSection title="Analyte Filters">
            <Dropdown title="Iron">
              <RangeList
                constructRange={constructRange}
                attributes={iron}
                query={query}
              />
            </Dropdown>
            <Dropdown title="Carbon">
              <RangeList
                constructRange={constructRange}
                attributes={carbon}
                query={query}
              />
            </Dropdown>
            <Dropdown title="Sulfur">
              <RangeList
                constructRange={constructRange}
                attributes={sulfur}
                query={query}
              />
            </Dropdown>
            <Dropdown title="Nitrogen">
              <RangeList
                constructRange={constructRange}
                attributes={nitrogen}
                query={query}
              />
            </Dropdown>
            <Dropdown title="Metal Isotopes">
              <RangeList
                constructRange={constructRange}
                attributes={metalIsotope}
                query={query}
              />
            </Dropdown>

            <Dropdown title="Elemental Data">
              <RangeList
                constructRange={constructRange}
                attributes={customElements}
                query={query}
              />
            </Dropdown>
          </FilterSection>
        )}

        <FilterSection title="Show">
          {(query.type === "samples" || query.type === "nhhxrf") && (
            <>
              <CheckboxGroup
                colWidth={200}
                title="Iron"
                data={iron}
                isChecked={this.isChecked}
                onChange={changeShow}
              />
              <CheckboxGroup
                colWidth={200}
                title="Carbon"
                data={carbon}
                isChecked={this.isChecked}
                onChange={changeShow}
              />
              <CheckboxGroup
                colWidth={200}
                title="Sulfur"
                data={sulfur}
                isChecked={this.isChecked}
                onChange={changeShow}
              />
              <CheckboxGroup
                title="Nitrogen"
                data={nitrogen}
                isChecked={this.isChecked}
                onChange={changeShow}
              />
              <CheckboxGroup
                colWidth={200}
                title="Metal Isotopes"
                data={metalIsotope}
                isChecked={this.isChecked}
                onChange={changeShow}
              />
              <CheckboxGroup
                colWidth={130}
                title="Elemental Data"
                data={customElements}
                isChecked={this.isChecked}
                onChange={changeShow}
              />
            </>
          )}
          <CheckboxGroup
            title="Samples Context"
            colWidth={200}
            data={defaultShow}
            isChecked={this.isChecked}
            onChange={changeShow}
          />

          {query.type === "analyses" && (
            <CheckboxGroup
              title="Analysis Details"
              data={defaultShowAnalysis}
              isChecked={this.isChecked}
              onChange={changeShow}
            />
          )}
          <CheckboxGroup
            title="References"
            data={defaultShowReferences}
            isChecked={this.isChecked}
            onChange={changeShow}
          />
        </FilterSection>
      </DrawerPanel>
    );
  }
}

export default SideBar;
