import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isNil } from 'lodash';
import React from 'react';

type Props = {
    value: string;
    onChange: (value: string) => void;
}

const TypeSelector = ({ value, onChange }: Props) => {
    const handleChange = (e: any) => {
        const v = e.target.value;
        if (isNil(v)) {
            return;
        }
        onChange(v);
    }

    const classes = useStyles();

    return (
        <ToggleButtonGroup
            color='primary'
            classes={{
                root: classes.root
            }}
            exclusive={true}
            onChange={handleChange}
            value={value}
            size="small"
        >
            <ToggleButton
                value="samples"
            >
                Samples
            </ToggleButton>
            <ToggleButton
                value="analyses"
            >
                Analyses
            </ToggleButton>
            <ToggleButton
                value="nhhxrf"
            >
                No HHXRF
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

const useStyles = makeStyles(() => ({
    root: {
        alignSelf: 'center',
        padding: '10px 0px'
    }
}))

export default TypeSelector;