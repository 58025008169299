import { Typography } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';

type Props = {
    countries?: number,
    samples?: number,
    results?: number
}

const formatNumber = (number?: number) => {
    if (isNil(number)) {
        return '-';
    }

    return number.toLocaleString();
}

const Counters = ({ countries, samples, results }: Props) => {
    return (
        <div id="wrapper">
            <div id="c1" className='section-data'>
                <Typography variant='h4'>
                    {formatNumber(countries)}
                </Typography>
                <Typography variant='h5'>
                    Countries
                </Typography>
            </div>
            <div id="c2" className='section-data'>
                <Typography variant='h4'>
                    {formatNumber(samples)}
                </Typography>
                <Typography variant='h5'>
                    Samples
                </Typography>
            </div>
            <div id="c3" className='section-data'>
                <Typography variant='h4'>
                    {formatNumber(results)}
                </Typography>
                <Typography variant='h5'>
                    Results
                </Typography>
            </div>
        </div>
    )
}

export default Counters;